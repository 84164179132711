import Banner from "@/components/Banner/Banner";
import Certificate from "@/components/Certificate/Certificate";
import Partners from "@/components/Common/Partners.jsx";
import Customer from "@/components/Customer/Customer";
import SmartHomeSolutions from "@/components/SmartHomeSolutions/SmartHomeSolutions";
import baseApiUrl from "@/utils/baseApiUrl";
import baseLocalApiUrl from "@/utils/baseLocalApiUrl";
import baseUrl from "@/utils/baseUrl";
import Layout from "layout/home";
import dynamic from "next/dynamic";
import Head from "next/head";
import { useRouter } from "next/router";
/* Dynamic import */
const ServiceAndProject = dynamic(
  () => import("@/components/ServiceAndProject/ServiceAndProject"),
  {
    ssr: false,
  }
);
const Archievement = dynamic(
  () => import("@/components/Archievement/Archievement"),
  {
    ssr: false,
  }
);
const WhyChooseUs = dynamic(() => import("@/components/Banner/WhyChooseUs"), {
  ssr: false,
});
const ClientsTestimonial = dynamic(
  () => import("@/components/ClientsTestimonial/ClientsTestimonial"),
  {
    ssr: false,
  }
);
const OurBlogAndNews = dynamic(
  () => import("@/components/Blog/OurBlogAndNews"),
  {
    ssr: false,
  }
);

export async function getServerSideProps(context) {
  const { locale } = context;
  //seo
  const resSeo = await fetch(
    `${baseLocalApiUrl}/api/banner?populate[0]=seo&populate[1]=seo.metaSocial&populate[2]=seo.metaSocial.image&locale=${locale}`
  );
  const { data: Seo } = await resSeo.json();

  /* Banner */
  const banners = await fetch(
    `${baseLocalApiUrl}/api/banner?populate[0]=banners&populate[banners][populate][0]=image&populate[banners][populate][1]=imageBackground&locale=${locale}`
  ).then(async (res) => (await res.json())?.data?.attributes?.banners || []);

  /* Blogs */
  const blogs = await fetch(
    `${baseLocalApiUrl}/api/blogs?populate[0]=image&fields[0]=title&fields[1]=slug&fields[2]=shortText&fields[3]=date&sort[0]=createdAt:desc&pagination[start]=0&pagination[limit]=3&locale=${locale}`
  ).then(async (res) => (await res?.json())?.data || []);

  /* Statements */
  const statements = await fetch(
    `${baseLocalApiUrl}/api/statement?populate=*&locale=${locale}`
  ).then(
    async (res) => (await res?.json())?.data?.attributes?.StatementInfo || []
  );

  /* About Us */
  const aboutUs = await fetch(
    `${baseLocalApiUrl}/api/about-us?populate=*&locale=${locale}`
  ).then(async (res) => (await res?.json())?.data?.attributes || {});

  /* Service And Project */
  const lastServices = await fetch(
    `${baseLocalApiUrl}/api/services?fields[0]=title&fields[1]=iconName&fields[2]=shortText&fields[3]=slug&sort[0]=createdAt:desc&pagination[start]=0&pagination[limit]=5&locale=${locale}`
  ).then(async (res) => (await res?.json())?.data || {});
  const lastProjects = await fetch(
    `${baseLocalApiUrl}/api/projects?fields[0]=title&fields[1]=slug&fields[2]=projectDetailsText&populate[0]=image&sort[0]=createdAt:desc&pagination[start]=0&pagination[limit]=10&locale=${locale}`
  ).then(async (res) => (await res?.json())?.data || {});

  /* Client Testimonial */
  const clientsTestimonial = await fetch(
    `${baseLocalApiUrl}/api/testimonials-style-1?populate[0]=item&populate[item][populate][0]=image&locale=${locale}`
  ).then(async (res) => (await res?.json())?.data || {});

  /*  Why Choose Us */
  const whyChooseUs = await fetch(
    `${baseLocalApiUrl}/api/why-choose-us?populate=*&locale=${locale}`
  ).then(async (res) => (await res?.json())?.data || {});

  /* Interesting Number */
  const interestingNumbers = await fetch(
    `${baseLocalApiUrl}/api/interesting-number?populate=*&locale=${locale}`
  ).then(
    async (res) =>
      (await res?.json())?.data?.attributes?.interestingNumbers || []
  );
  /* Customer */
  const customers = await fetch(
    `${baseLocalApiUrl}/api/customer?populate=*&locale=${locale}`
  ).then(async (res) => (await res?.json())?.data || []);
  /* Solutions */
  const solutions = await fetch(
    `${baseLocalApiUrl}/api/solution?populate[solutions][populate]=smartHomeSolution.image&locale=${locale}`
  ).then(async (res) => (await res?.json())?.data?.attributes?.solutions || {});
  /* Solutions */
  const certificates = await fetch(
    `${baseLocalApiUrl}/api/certificate?populate[0]=Certificates&populate[Certificates][populate]=*&locale=${locale}`
  ).then(async (res) => (await res?.json())?.data?.attributes);
  return {
    props: {
      seo: Seo?.attributes.seo,
      banners,
      blogs,
      statements,
      aboutUs,
      certificates,
      serviceAndProject: {
        lastServices,
        lastProjects,
      },
      clientsTestimonial,
      whyChooseUs,
      interestingNumbers,
      customers,
      solutions,
    },
  };
}

export default function HomePage({
  seo,
  banners,
  serviceAndProject,
  clientsTestimonial,
  interestingNumbers,
  customers,
  solutions,
  certificates,
}) {
  // const [componentsState, setComponentsState] = useState({
  //   ["service-and-project"]: false,
  //   ["archievement"]: false,
  //   ["why-choose-us"]: false,
  //   ["client-testimonial"]: false,
  //   ["our-blog-and-news"]: false,
  // });

  // useEffect(() => {
  //   scrollToVisible(
  //     [
  //       "service-and-project",
  //       "archievement",
  //       "why-choose-us",
  //       "client-testimonial",
  //       "our-blog-and-news",
  //     ],
  //     componentsState,
  //     setComponentsState
  //   );
  // }, []);
  const router = useRouter();
  return (
    <>
      <Head>
        <title>{seo?.metaTitle}</title>
        <meta name="description" content={seo?.metaDescription} />
        <meta
          property="og:title"
          content={seo?.metaSocial[0]?.title}
          key="title"
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta
          property="og:image"
          content={`${baseApiUrl}${seo?.metaSocial[0]?.image?.data?.attributes?.url}`}
        />
        <meta
          property="og:description"
          content={seo?.metaSocial[0]?.description}
        />
        <meta property="og:url" content={`${baseUrl}${router.asPath}`} />
        <meta
          name="twitter:image"
          content={`${baseApiUrl}${seo?.metaSocial[1]?.image?.data?.attributes?.url}`}
        />
        <meta property="twitter:title" content={seo?.metaSocial[1]?.title} />
        <meta
          property="twitter:description"
          content={seo?.metaSocial[1]?.description}
        />
        <meta property="twitter:url" content={`${baseUrl}${router.asPath}`} />
      </Head>
      <div className="dark:tw-bg-_black-900">
        {/* <PopUpModal /> */}
        <Banner
          banners={banners?.map((banner, index) => {
            return {
              image: {
                src:
                   banner?.image?.data?.attributes?.url,
                alt:
                  banner?.image?.data?.attributes?.alternativeText ||
                  "banner-" + index,
              },
              subTitle: banner?.subTitle,
              title: banner?.title,
              imageBg: {
                src:
                  banner?.imageBackground?.data.attributes?.url,
                alt:
                  banner?.imageBackground?.data.attributes.alternativeText ||
                  "bannerbg-" + index,
              },
              description: banner?.description,
              linkButton1: banner?.link1,
              linkButton2: banner?.link2,
            };
          })}
        />
        <div id="archievement">
          <Archievement interestingNumbers={interestingNumbers} />
        </div>
        {/* <div className="tw-mt-[120px]">
          <Statements statements={statements} />
        </div> */}
        <div className="section-spacing" >
          <SmartHomeSolutions solutions={solutions} />
        </div>
        <div className="section-spacing  background-theme-even">
          <Customer customers={customers} className=" !tw-px-0"/>
        </div>
        <div className="section-spacing">
          <Certificate certificates={certificates} />
        </div>
        {/* <div id="about-us" className="tw-mt-[120px]">
          <AboutUs
            title={aboutUs?.title}
            description={aboutUs?.description}
            subTitle1={aboutUs?.subTitle1}
            subDescription1={aboutUs?.subDescription1}
            subTitle2={aboutUs?.subTitle2}
            subDescription2={aboutUs?.subDescription2}
            linkVideoYoutube={aboutUs?.link_video_youtube}
            ourServices={aboutUs?.our_services}
            image={{
              src:
                baseLocalApiUrlNextjs + aboutUs?.image?.data?.attributes?.url,
              alt:
                aboutUs?.image?.data?.attributes?.alternativeText || "about-us",
            }}
          />
        </div> */}
        <div id="service-and-project" className="">
          {/* {componentsState["service-and-project"] && ( */}
            <ServiceAndProject
              lastProjects={serviceAndProject?.lastProjects?.map(
                (lastProject, index) => ({
                  title: lastProject.attributes.title,
                  slug: lastProject.attributes.slug,
                  image: {
                    src:
                       lastProject?.attributes?.image?.data?.attributes?.url,
                    alt:
                      lastProject.attributes.image.data.attributes
                        .alternativeText || "project-image" + index,
                  },
                })
              )}
              lastServices={serviceAndProject?.lastServices?.map(
                (lastService) => ({
                  slug: lastService.attributes.slug,
                  iconName: lastService.attributes.iconName,
                  title: lastService.attributes.title,
                  shortText: lastService.attributes.shortText,
                })
              )}
            />
          {/* )} */}
        </div>

        {/* <div id="why-choose-us">
          {componentsState["why-choose-us"] && (
            <WhyChooseUs
              reasons={whyChooseUs?.attributes?.reason}
              image={whyChooseUs?.attributes?.image}
            />
          )}
        </div> */}
        <div id="client-testimonial" className="section-spacing">
          {/* {componentsState["client-testimonial"] && ( */}
            <ClientsTestimonial
              subTitle={clientsTestimonial?.attributes}
              image={clientsTestimonial?.attributes?.image?.data?.attributes}
              reviews={clientsTestimonial?.attributes?.item}
              title={clientsTestimonial?.attributes}
            />
          {/* )} */}
        </div>
        <div className="section-spacing background-theme-even">
          <Partners />
        </div>
        {/* <div
          id="our-blog-and-news"
          className="tw-relative tw-mt-[120px] tw-flex tw-flex-col tw-gap-5
                  tw-bg-[url('/images/background-near-footer-light.webp')] tw-bg-[length:100%_100%]
                  tw-bg-no-repeat dark:tw-bg-[url('/images/background-near-footer-dark.webp')]"
        >
          {componentsState["our-blog-and-news"] && (
            <OurBlogAndNews blogs={blogs} />
          )}
        </div> */}
      </div>
    </>
  );
}

HomePage.getLayout = function getLayout(page) {
  return <Layout>{page}</Layout>;
};
