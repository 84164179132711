import generateTitle from "@/utils/generateTitle";
import { useRouter } from "next/router";
import { useEffect } from "react";
import MyIcon from "../MyIcon/MyIcon";

export default function Customer({ customers }) {
  const { locale } = useRouter();
  useEffect(() => {
    const swiperDevice = document.querySelector(
      `swiper-container#customer-swiper`
    );
    const swiperDeviceParams = {
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
      grid: {
        rows: 1,
        fill: "row",
      },

      loopAddBlankSlides: true,
      breakpoints: {
        0: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },
        576: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },
        768: {
          slidesPerView: 2,
          slidesPerGroup: 1,
        },
        992: {
          slidesPerView: 3,
          slidesPerGroup: 1,
        },
      },
      spaceBetween: 30,
      on: {
        afterInit: function (swiper) {
          // calculate height of swiper
          var maxHeight = 0;
          swiper.slides.forEach((slide) => {
            const slideHeight = slide.offsetHeight;
            if (slideHeight > maxHeight) {
              maxHeight = slideHeight;
            }
          });
          //set height for swiper using current height calculated
          swiperDevice.style.height = `${maxHeight}px`;
        },
      },
      pagination: {
        dynamicBullets: true,
      },
    };
    Object.assign(swiperDevice, swiperDeviceParams);
    swiperDevice.initialize();
  }, []);
  return (
    <div className="container tw-mx-auto">
      {/* <div className="tw-flex tw-justify-center tw-gap-[10px]">
        <Image alt="sun" src={sunImg} />
        <p className="tw-font-bold !tw-text-_red-600 lg:tw-text-body-lg dark:!tw-text-_red-700">
          {locale === "vi" ? "Khách Hàng của Thạch Anh ITT" : "About Us"}
        </p>
      </div> */}
      <p
        className="main-title [&>span]:!tw-text-black-600 [&>span:nth-child(2)]:!tw-text-_red-600 [&>span]:dark:!tw-text-_red-700"
      >
       {generateTitle(customers?.attributes?.title)}
      </p>
      <swiper-container id="customer-swiper" class="tw-flex tw-items-stretch tw-h-fit" init="false">
        {customers?.attributes?.Customer.map((customer, index) => (
          <swiper-slide
            class="swiper-gap tw-self-stretch tw-flex-grow tw-h-full"
            key={index}
          >
            <div className="tw-text-center tw-text-wrap tw-h-full tw-bg-gray-200 dark:tw-bg-black-700 lg:tw-py-12 tw-w-fit tw-rounded-xl tw-text-white tw-p-6">
              <div className="tw-bg-red tw-flex tw-w-20 tw-mx-auto tw-h-20 tw-mb-[35px]  tw-place-items-center tw-justify-center tw-rounded-3xl tw-p-6">
                <MyIcon
                  iconName={customer?.iconName}
                  color="white"
                  fontSize={32}
                  className="tw-font-bold dark:tw-text-white tw-text-[20px]"
                />
              </div>
              <h3 className="h5 dark:tw-text-white tw-capitalize tw-font-semibold tw-text-[20px] tw-leading-7">
                {customer?.title}
              </h3>
              <p className="tw-mb-0 dark:tw-text-white">
                {customer?.description}
              </p>
            </div>
          </swiper-slide>
        ))}
      </swiper-container>
    </div>
  );
}

Customer.defaultProps = {
  title: (
    <>
      Empower your business with IT most the
      <span> Solutions</span>
    </>
  ),
  description:
    "IT Solution is a broad category that encompasses various technological solutions aimed at solving problems or improving efficiency in the field of information technology the best it design in the field",
  linkVideoYoutube: "https://www.youtube.com/embed/tgbNymZ7vqY",
  ourServices: [
    { name: "Cloud Connect IT Services design" },
    { name: "Cyber Shield Technologies here" },
    { name: "Digital Transfor Solutions our te" },
    { name: "IT Consulting and Support" },
  ],
};
